import React from "react";
import CBN from "../../images/gbrCBN/CBNBanner.webp";
import { NavLink } from "react-router-dom";
import "./OurServices.css"
import { Row, Col } from "react-bootstrap";
import { Container } from "@material-ui/core";

export default function OurServices() {
  const services = [
    // {
    //   title: "ABN",
    //   image: ABN,
    //   href: "/ABN",
    // },
    {
      title: "ARN",
      image: CBN,
      href: "/customized-arn-network",
    },
    // {
    //   title: "PN",
    //   image: PN,
    //   href: "/ProductionNetwork",
    // },
    // {
    //     title: "GTN",
    //     image: GTN,
    //     href: "/ExploreProducts",
    //   },
  ];

  return (
    <Container className="container for-farmer-section2 subSectionWrapper ">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
        GBR Care ARN Network
      </h2>
      <Row className="justify-content-center">
        {services.map((e,i) => {
          return (
            <Col md={4} key={i}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
