import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function CollectivesBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Access to Loan",
      bodycopy:
        "Access to loans is a significant benefit for collectives, enabling them to secure essential funding for expansion and operational needs. This financial support empowers collectives to enhance their productivity and achieve sustainable growth.   ",
    },
    {
      image: Icon10,
      title: "Easy Distribution of Products",
      bodycopy:
      "Easy distribution of products is a benefit for collectives, facilitated by robust mechanisms that ensure and verify product delivery to farmers. This efficiency enhances supply chain management and reduces operational bottlenecks."
    },
    // {
    //   image: Icon6,
    //   title: "Access to Machinery ",
    //   bodycopy:
    //     "Progressive farmers & micro small organisations benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    // },
  
    // {
    //   image: Icon8,
    //   title: "Access to farmers facilitation services ",
    //   bodycopy:
    //     "Experience the advantages of our seamless field support, including migration assistance, asset care, labor card facilitation, and buyback support. This comprehensive package ensures operational efficiency and provides peace of mind for farmers."
    // },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Collective Benefits</h2>
      <Row>
        {benefits.map((e,i) => {
          return (
            <Col md={4} key={i}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
