import React from "react";
import HeaderComponent from "../../components/WebSite/Header/HeaderComponent";
import FooterComponent from "../../components/WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../../components/WebSite/HomeSubSection/HomeSubSection";
import "./FarmerFinacialService.css";

export default function FarmerFinacialService() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        bannerText="Financial Service"
        className="financial-service-bg"
      />

      <div className="main-wrapper2">
        <div className="subSectionWrapper">
          {/* <HomeSubSection
        className3="loan-sevice"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Loan"
        description="GBR facilitates loan services to farmers, empowering them with financial support to enhance their farming operations. Through tailored loan products and accessible lending processes, GBR assists farmers in securing capital for purchasing seeds, fertilizers, equipment, and other inputs necessary for their agricultural activities. By providing financial assistance, GBR contributes to the growth and sustainability of agricultural ventures, ultimately fostering the development of rural communities and promoting food security.
        "
      
       
        
      /> */}

          <HomeSubSection
            className3="loan-sevice"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="
        Loan"
            // description="GBR facilitates loan services to farmers, empowering them with financial support to enhance their farming operations. Through tailored loan products and accessible lending processes, GBR assists farmers in securing capital for purchasing seeds, fertilizers, equipment, and other inputs necessary for their agricultural activities. By providing financial assistance, GBR contributes to the growth and sustainability of agricultural ventures, ultimately fostering the development of rural communities and promoting food security.
            // "
            description="GBR Care offers a comprehensive suite of financial services, including asset loans tailored for agricultural fields and processing assets. Their lending solutions empower farmers to acquire vital resources for their operations, whether expanding agricultural holdings or investing in processing equipment. With accessible lending processes and tailored loan products, GBR Care facilitates farmers' access to capital for purchasing seeds, fertilizers, equipment, and other essential inputs. This support fosters agricultural growth, sustainability, and community development while promoting food security."
          />

          <HomeSubSection
            className3="insurance-sevice"
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards"
            descriptionTitle="
        Insurance"
            // description="GBR facilitates loan services to farmers, empowering them with financial support to enhance their farming operations. Through tailored loan products and accessible lending processes, GBR assists farmers in securing capital for purchasing seeds, fertilizers, equipment, and other inputs necessary for their agricultural activities. By providing financial assistance, GBR contributes to the growth and sustainability of agricultural ventures, ultimately fostering the development of rural communities and promoting food security.
            // "
            description="GBR Care extends its services to include comprehensive insurance solutions, covering both livestock and farms. By facilitating insurance coverage for livestock and agricultural assets, GBR Care offers peace of mind to farmers, safeguarding their investments against unforeseen risks such as disease outbreaks, natural disasters, or accidents. With tailored insurance packages, GBR Care ensures that farmers have the protection they need to mitigate financial losses and sustain their operations in the face of adversity."
          />
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
