import React from 'react'
import StepCards from '../stepCards/StepCards'
import step1 from "../../images/FarmersJourney/phone-call (1).svg";
import step4 from "../../images/ae-journey/ae1.png";

// import step1 from "../../images/ae-journey/ae1.png";
import step2 from "../../images/ae-journey/ae2.png";

// import step5 from "../../images/retailer.svg";
import step3 from "../../images/step2.png";
// import step5 from "../../images/ae-journey/ae4.png";
// import img5 from "../../images/ae-journey/ae5.png";
// import img1 from "../../images/optionalChoice.png";
// import { Row, Col } from "react-bootstrap";
// import { Container } from '@material-ui/core';

export default function CollectivesJourney() {
    const stepCardContent = [
        {
          title: "Step 1",
          bodycopy: " Contact a GBR Care agent to initiate an account or request the creation of an account.",
          img: step1,
        },
        {
          title: "Step 2",
          bodycopy: "Provide the required identification and address proof documents to finalize your account creation.",
          img: step2,
        },
        {
          title: "Step 3",
          bodycopy: "Download the application and log in to your account.",
          img: step3,
        },
        {
          title: "Step 4",
          bodycopy:
            "Buy and sell products from farmers .",
          img: step4,
        },
        // {
        //   title: "Step 5",
        //   bodycopy:
        //   "Receive agri-products & manage them in the stores.",
        //   img: step5,
        // },
        // {
        //     title: "Step 5",
        //     bodycopy:
        //       "Share report with GBR care.",
        //     img: img5,
        //   },
      ];
  return (
    <>
    <h2 className="text-center container-title mb-4">
    Collectives Journey
  </h2>
    <div className='section3BodyContainer justify-content-md-center p-0'>
         
    {stepCardContent.map((e,i) =>{
               return (
                <StepCards
                key={i}
                  src={e.img}
                  stepTitle={e.title}
                  stepContent={e.bodycopy}
                />
              );
        })}    </div>
        </>
  )
}
