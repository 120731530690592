import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/Farmer_Needs_for_GBR_Care_04-03-2024-removebg-preview.png";
import Advice from "../../../images/communication.svg";
import Profit from "../../../images/increase.svg";
import Quality from "../../../images/quality-control.svg";

import Marketing from "../../../images/insurance.svg";

// import "./ChallangesSection.css";

export default function ForFarmerChallenges() {
  const challanges =[
    {
      title:"Quality Inputs",
      description:"Quality inputs are essential for farmers to achieve optimal yield, crop health, and profitability while meeting global food demands and ensuring agricultural sustainability.",
      image:Quality
    },
    {
      title:"Profitable Sale",
      description:"Profitable sales are crucial for farmers as they ensure income stability, cover production expenses, and support sustainable farming practices, ultimately securing their livelihoods.",
      image:Profit
    },
    {
      title:"Actionable Advisory",
      description:"Actionable advisory is essential for farmers to make informed decisions, optimize resource usage, and mitigate risks, thereby improving productivity and profitability while ensuring sustainable agricultural practices.",
      image:Advice
    },

    {
      title:"Insurance",
      description:"Insurance is a necessity for farmers as it provides crucial financial protection against crop losses, natural disasters, and market uncertainties, securing their livelihoods and ensuring stability in food production.",
      image:Marketing
    },

]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        Farmers Needs
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} alt="challange" className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e,i)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex" key={i}>
              <div className="challangeCards  mb-md-4 ">
                <img src={e.image} alt="Challange Icon" className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p className="mb-0">
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}          
          </Row>
   
        </Col>
      </Row>
    </Container>
  );
}
