import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { NotificationManager } from "react-notifications";
import InputField from "../technologyModal/InputFields/InputField";
import successImg from "../../images/ServiceIcons/success.svg";
import "./SiteWeatherModal.css";
import Location from "../../images/weather/location.svg";
import Calendar from "../../images/weather/calendar.svg";
import Temperature from "../../images/weather/temperature.svg";
import Clouds from "../../images/weather/cloud-computing.svg";
import ClearWeather from "../../images/weather/clear-sky.svg";
import Mist from "../../images/weather/mist.svg";
import Haze from "../../images/weather/haze.svg";
import Fog from "../../images/weather/foggy.svg";
import Smoke from "../../images/weather/dust.svg";
import ThunderStrom from "../../images/weather/strom.svg";
import Drizzle from "../../images/weather/drizzle.svg";
import Rain from "../../images/weather/rainy.svg";
import Snow from "../../images/weather/snowfall.svg";
import Tornado from "../../images/weather/tornado.svg";
import moment from 'moment';

export default function SitwWeatherModal({ props, Show, onclicked, forecast }) {
// alert(Show)
  const[forDate, setForDate] = useState()
  const dateBuilder = (d) => {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let day = days[d.getDay()];

    let date = d.getDate();

    let month = months[d.getMonth()];
    let year = d.getFullYear();

    return `${day} ${date} ${month} ${year}`;
  };

  const checkDate = forecast.map((e) =>{
    return e.dt
  })

  const formattedDates = checkDate.map(apiDate => {
    const parsedDate = moment(apiDate, 'DD/MM/YYYY HH:mm:ss');
    const formattedDate = parsedDate.format('YYYY-MM-DD');
    return formattedDate;
  });

  return (
    <div className="technologyModal">
      <Modal
        show={Show}
        onHide={onclicked}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="form-header">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="form-title mb-0 me-5">Weather Information</h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {typeof props.main != "undefined" ? (
            <div className="wether scrollable-container">
              {/* <Row>
                <Col md={6}>
                <div className="location"> <img src={Location} className="weatherIcons" alt="icon"/> {props.name}, {props.sys.country}</div>
                   
             <div className="date"><img src={Calendar} className="weatherIcons" alt="icon"/> {dateBuilder(new Date())}</div>
             <img src={Temperature} className="weatherIcons" alt="icon"/>  {Math.round(props.main.temp)}°c

                </Col>

                <Col md={6}>
                <div  className="weather"><img src={Clouds} className="weatherConditions d-block" alt="icon"/> {props.weather[0].main}</div>

                </Col>
              </Row> */}
              <div className="location-box">
                <div className="location d-flex align-items-center mb-2">
                  {" "}
                  <img
                    src={Location}
                    className="weatherIcons"
                    alt="icon"
                  />{" "}
                  <h2 className="container-para mb-0 d-inline-block">
                    {" "}
                    {props.name}, {props.sys.country}{" "}
                  </h2>
                </div>
                <div className="date d-flex align-items-center mb-2">
                  <img src={Calendar} className="weatherIcons" alt="icon" />{" "}
                  <h2 className="container-para mb-0 d-inline-block">
                    {dateBuilder(new Date())}
                  </h2>
                </div>
              </div>
              <div className="weather-box">
                <div className="temp d-flex align-items-center mb-2">
                  <img src={Temperature} className="weatherIcons" alt="icon" />{" "}
                  <h2 className="container-para mb-0 d-inline-block">
                    {" "}
                    {Math.round(props.main.temp)}°c{" "}
                  </h2>
                </div>
                <div className="weather d-flex align-items-center mb-2">
                  {props.weather[0].main === "Clouds" && (
                    <img src={Clouds} className="weatherIcons" alt="icon" />
                  )}
                  {props.weather[0].main === "Clear" && (
                    <img
                      src={ClearWeather}
                      className="weatherIcons"
                      alt="icon"
                    />
                  )}
                  {props.weather[0].main === "Thunderstorm" && (
                    <img
                      src={ThunderStrom}
                      className="weatherIcons"
                      alt="icon"
                    />
                  )}
                  {props.weather[0].main === "Drizzle" && (
                    <img src={Drizzle} className="weatherIcons" alt="icon" />
                  )}
                  {props.weather[0].main === "Rain" && (
                    <img src={Rain} className="weatherIcons" alt="icon" />
                  )}
                  {props.weather[0].main === "Snow" && (
                    <img src={Snow} className="weatherIcons" alt="icon" />
                  )}
                  {props.weather[0].main === "Mist" && (
                    <img src={Mist} className="weatherIcons" alt="icon" />
                  )}
                  {props.weather[0].main === "Haze" && (
                    <img src={Haze} className="weatherIcons" alt="icon" />
                  )}
                  {props.weather[0].main === "Fog" && (
                    <img src={Fog} className="weatherIcons" alt="icon" />
                  )}
                  {props.weather[0].main === "Tornado" && (
                    <img src={Tornado} className="weatherIcons" alt="icon" />
                  )}
                  {(props.weather[0].main === "Smoke" ||
                    props.weather[0].main === "Sand" ||
                    props.weather[0].main === "Dust" ||
                    props.weather[0].main === "Ash" ||
                    props.weather[0].main === "Squall") && (
                    <img src={Smoke} className="weatherIcons" alt="icon" />
                  )}

                  <h2 className="container-para mb-0 d-inline-block">
                    {" "}
                    {props.weather[0].main}
                  </h2>
                </div>
              </div>

              <h2 className="container-heading1 mb-4 text-center">
                Weather Forecast for 5 days
              </h2>

              <div className="d-flex weather-box-wrapper">
                {forecast.map((props) => {
                  return (
                    <>
                      
                        <div className="weather-box weather-box--mod ">
                          <div className="date d-flex align-items-center mb-2">
                          <img
                            src={Calendar}
                            className="weatherIcons"
                            alt="icon"
                          />{" "}
                          <h2 className="container-para mb-0 d-inline-block ">
                            {props.dt_txt}
                            

                            {/* `${new Date(props.dt*1000)}` */}
                          </h2>
                          </div>
                          <div className="temp d-flex align-items-center mb-2">
                            <img
                              src={Temperature}
                              className="weatherIcons"
                              alt="icon"
                            />{" "}
                            <h2 className="container-para mb-0 d-inline-block">
                              {" "}
                              {Math.round(props.main.temp)}°c{" "}
                            </h2>
                          </div>
                          <div className="weather d-flex align-items-center mb-2">
                            {props.weather[0].main === "Clouds" && (
                              <img
                                src={Clouds}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Clear" && (
                              <img
                                src={ClearWeather}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Thunderstorm" && (
                              <img
                                src={ThunderStrom}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Drizzle" && (
                              <img
                                src={Drizzle}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Rain" && (
                              <img
                                src={Rain}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Snow" && (
                              <img
                                src={Snow}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Mist" && (
                              <img
                                src={Mist}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Haze" && (
                              <img
                                src={Haze}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Fog" && (
                              <img
                                src={Fog}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {props.weather[0].main === "Tornado" && (
                              <img
                                src={Tornado}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}
                            {(props.weather[0].main === "Smoke" ||
                              props.weather[0].main === "Sand" ||
                              props.weather[0].main === "Dust" ||
                              props.weather[0].main === "Ash" ||
                              props.weather[0].main === "Squall") && (
                              <img
                                src={Smoke}
                                className="weatherIcons"
                                alt="icon"
                              />
                            )}

                            <h2 className="container-para mb-0 d-inline-block">
                              {" "}
                              {props.weather[0].main}
                            </h2>
                          </div>
                        </div>
                    
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
