import React, {useState} from "react";
import { Container, Grid } from "@material-ui/core";
import "./HomeSubSection.css";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { Col, Row} from "react-bootstrap";
import DemoModal from "../../DemoModal/DemoModal";

export default function HomeSubSection({
  src,
  description,
  descriptionTitle,
  descriptionTitle2,

  description2,
  className,
  className1,
  description1,
  href1,
  href2,
  href3,
  href4,
  href5,
  href7,
  buttoncont1,
  buttoncont2,
  buttoncont3,
  buttoncont7,
  onClick,
  data,
  id,
  fontBold,
  listItems,
  grid,
  className3,
  exploreButton,
  userType,
  demoTrue,
  id1,id2,
  btn2
}) {

  const [demoModal, setDemoModal] = useState(false);
  const [id11, setId1] = useState(id1);
  const [id22, setId2] = useState(id2);
  
    const handleClick = (event) => {
      setDemoModal(true)
    };
  
    const hideModal = () =>{
      setDemoModal(false);
      setId2("");
      setId1("");
    }
  const loginHandler = (username) => {
    alert(username)
    localStorage.setItem("userType", username);
  };

  // alert(username)
  return (<>
   <DemoModal show={demoModal} onHide={hideModal} id1={id11}  id2={id22}/>

    <div className={`${className1} homeSubSection`} id={id}>
      <Container>
        <Row
          
          spacing={0}
          className={`${className} justify-content-center`}
        >
          {!grid ? (
            <>
              <Col lg={8}  md={6} sm={12} xs={12} className="content-wrapper">
                {descriptionTitle && (
                  <p
                    className={`${
                      fontBold
                        ? "container-heading2 fontBold"
                        : "container-heading2"
                    } `}
                  >
                    {descriptionTitle}
                  </p>
                )}

                <p className="container-para">{description}</p>
                {listItems && listItems}

                {description1 && (
                  <p className="container-para">{description1}</p>
                )}

{descriptionTitle2 && (
                  <p
                    className={`${
                      fontBold
                        ? "container-heading2 fontBold"
                        : "container-heading2"
                    } `}
                  >
                    {descriptionTitle2}
                  </p>
                )}
      {description2 && (
                  <p className="container-para">{description2}</p>
                )}
                {href1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                  >
                    <NavLink to={href1}>{buttoncont1}</NavLink>
                  </Button>
                )}
  {demoTrue && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                    id={id2} onClick = {handleClick}
                  >
                   {buttoncont1}
                  </Button>
                )}
                {href4 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                  >
                    <a href={href4} target="_blank">
                      {buttoncont1}
                    </a>
                  </Button>
                )}

                {href5 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                  >
                    <a href={href5} target="_blank">
                      {buttoncont2}
                    </a>
                  </Button>
                )}

                {href2 && !exploreButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                    onClick={onClick}
                  >
                    <NavLink to={href2}>{buttoncont2}</NavLink>
                  </Button>
                )}

{href7 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod"
                    type="button"
                  >
                  <NavLink onClick={onClick}   className="d-inline-block"   to={{ pathname: href7, state: { customData: data } }
}>{buttoncont7}</NavLink>
                  </Button>)}

                {href3 && !exploreButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod"
                    type="button"
                  >
                    <NavLink to={href3}>{buttoncont3}</NavLink>
                  </Button>
                )}


                
              </Col>
              <Col lg={4}  md= {6} sm={12} xs={12} className="p-0">
                {className3 ? (
                  <div className={`${className3} communitycards`}></div>
                ) : (
                  <img
                    src={src}
                    width="100%"
                    alt="Image"
                    className="rowReverse"
                  />
                )}
              </Col>
            </>
          ) : (
            <>
              <Col md={7} sm={12} xs={12}>
                {descriptionTitle && (
                  <p
                    className={`${
                      fontBold
                        ? "container-heading2 fontBold"
                        : "container-heading2"
                    } `}
                  >
                    {descriptionTitle}
                  </p>
                )}

                <p className="container-para">{description}</p>
                {listItems && listItems}

                {description1 && (
                  <p className="container-para">{description1}</p>
                )}

                {href1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                  >
                    <NavHashLink to={href1}>{buttoncont1}</NavHashLink>
                  </Button>
                )}

                {href2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                  >
                    <NavLink to={href2}>{buttoncont2}</NavLink>
                  </Button>
                )}
                {href4 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                  >
                    <a href={href4} target="_blank">
                      {buttoncont1}
                    </a>
                  </Button>
                )}

                {href5 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                  >
                    <a href={href5} target="_blank">
                      {buttoncont2}
                    </a>
                  </Button>
                )}

                {href2 && !exploreButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod me-3"
                    type="button"
                    onClick={() => loginHandler(userType)}
                  >
                    <NavLink to={href2}>{buttoncont2}</NavLink>
                  </Button>
                )}
                {href3 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod"
                    type="button"
                  >
                    <NavLink to={href3}>{buttoncont3}</NavLink>
                  </Button>
                )}
  {href7 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="registerBtn--mod"
                    type="button"
                  >
                  <NavLink onClick={onClick}   className="d-inline-block"   to={{ pathname: href7, state: { customData: data } }
}>{buttoncont7}</NavLink>
                  </Button>
                )}

              </Col>
              <Col md={5} sm={12} xs={12} className="p-0">
                <img
                  src={src}
                  width="100%"
                  alt="Image"
                  className="rowReverse"
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
    </> );
}
