import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import AnimalCare from "../../../images/agrocaremarket/plant-care.jpg";
import PlantCare from "../../../images/agrocaremarket/animal-care.jpg";
import FarmingEquipments from "../../../images/agrocaremarket/farming-equipments.jpg";

export default function CBNNetworkProducts() {
  const services = [
    {
      title: "Animal Care Products",
      image: AnimalCare,
      href: "/auth/3/signin",
    },
    {
      title: "Plant Care Products",
      image: PlantCare,
      href: "/auth/3/signin",
    },
    {
      title: "Farming Equipments",
      image: FarmingEquipments,
      href: "/auth/3/signin",
    },
  ];

  return (
    <Container className="container for-farmer-section2 px-0  subSectionWrapper">
      <h2 className="text-center container-title mb-4">
        {" "}
        Agri-Retailer Services for Agri-Producer
      </h2>
      <Row className="justify-content-center ">
        {services.map((e,i) => {
          const navLinkProps = {
            to: { pathname: e.href },
          };

          if (e.customPropValue) {
            navLinkProps.to.state = { customProp: e.customPropValue };
          }
          return (
            <Col md={4} className="mb-4" key={i}>
              <NavLink to={e.href}>
                <div className="image-container mb-4">
                  <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-decoration-none text-center container-heading1 mt-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>

                <p
                  className="mb-0 container-para
"
                >
                  {" "}
                  {e.data}{" "}
                </p>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
