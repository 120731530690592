import React, { Component } from "react";
import "./SiteWeatherManagement.css";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import OutsideClickHandler from "react-outside-click-handler";
import { Row, Col } from "react-bootstrap";
import SitwWeatherModal from "../SiteWeatherModal/SiteWeatherModal";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";
class SiteWeatherManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      SelectedState: "govtDept@consumer2.com",
      organizationClick: false,
      categoryClick: false,
      districtClick: false,
      statesClick: false,
      adminsClick: false,
      filterList: [],
      farmSites: [],
      categoriesfilterList: [],
      districtsfilterList: [],
      statesfilterList: [],
      adminsfilterList: [],
      pageCount: 1,
      offset: 0,
      perPage: 3,
      currentPage: 0,
      cboUserName: [],
      postCategoryIdVlaue: "",
      subCategoriesList: [],
      copyOfTenders: [],
      contentOwners: [],
      userTypes: ["AssetDeveloper", "Food-Processor"],
      consumerUsers: [],
      weatherModal: false,
      Latitude: "",
      Longitude: "",
      weatherData: {},
      forecastWeatherData:[],
      ApiUserName:"",
      forecastDate:""
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    for (let i = 0; i < this.state.userTypes.length; i++) {
      this.joinClubValues(this.state.userTypes[i]);
    }
  }

  joinClubValues(userTypes) {
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}${userTypes}`,
      // `${window.appConfig.allConsumerUsreswithAssetType}${window.appConfig.Relam}${window.appConfig.Relam}${userTypes}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
       
        const getData = res.users.filter((e, index) =>{
          return e.username === 'NewAssetDeveloper6@gmail.com'

          // return e.username === 'Sapna.R@Altiux.com'
        })
        this.getContentOwnerContent(getData[0].username)
        this.setState((prevState) => ({
          consumerUsers: [...prevState.consumerUsers, res.users].flatMap(
            (num) => num
          ),
        }));
      })
      .catch((err) =>{ 
        console.log("err", err)});
  }

  // Content Owner
  contentOwnerDropdownClick() {
    $("#AssetDeveloperOwner").toggle();
  }

  getContentOwnerContent(AssetDeveloper) {
    this.setState({
      ApiUserName:AssetDeveloper
    })
    fetch(
      // `http://54.167.97.129:8080/EAP/getallsites/consumer2/${AssetDeveloper}/Farm`,
      `${window.appConfig.weatherUpdate}${window.appConfig.Relam}${AssetDeveloper}/Farm`,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {

        this.setState({
          farmSites: result.sites,
          pageCount: Math.ceil(result.sites.length / this.state.perPage),
        });
      })
      .catch((err) => console.log("err", err));
    $("#AssetDevelopers").hide();
  }

  // Content Owner End
  weatherReportHandler =(params, user) =>{
    this.weatherHandler(params, user);
    this.forecastHandler(params, user)
  }

  weatherHandler = (params, user) => {
    const data = {
      "lat": params.latitude,
      "long": params.longitude
  }
   const finalData = JSON.stringify(data)
  //  console.log(finalData,"finalData")
  //  alert(finalData)
    fetch(
       `http://54.185.221.117:8900/weather/v1/consumer2/consumer2/${user}/current`,
      // `//api.openweathermap.org/data/2.5/weather?lat=${params.latitude}&lon=${params.longitude}&appid=f00c38e0279b7bc85480c3fe775d518c&units=metric`,
      {
        method: "POST",
        headers:{
          "Content-Type" : "application/json",
          "networkid":"consumer2",
          "orgid":`${user}`,
          "username":`${user}`,
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
        body:finalData
      }
      
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          weatherData: result.weatherData,
          // weatherModal: true,
        });
      })
      .catch((err) => {
        console.log("err", err)});
        this.setState({
          weatherModal: true,
        });
  };

forecastHandler = (params, user) => {
    const data = {
      "lat": params.latitude,
      "long": params.longitude
  }
   const finalData = JSON.stringify(data)

    fetch(
`https://devicestaging.gbrservice.com/weather/v1/consumer2/consumer2/NewAssetDeveloper6@gmail.com/forecast`,
      //  `http://54.185.221.117:8900/weather/v1/consumer2/consumer2/${user}/forecast`,
      // `//api.openweathermap.org/data/2.5/weather?lat=${params.latitude}&lon=${params.longitude}&appid=f00c38e0279b7bc85480c3fe775d518c&units=metric`,
      {
        method: "POST",
        headers:{
          "Content-Type" : "application/json",
          "networkid":"consumer2",
          "orgid":`${user}`,
          "username":`${user}`,
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
        body:finalData
      }
      
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          forecastWeatherData: result.weatherData.list,
          weatherModal: true,
          // weatherModal: true,
          // forecastDate: result.dt
        });
      })
      .catch((err) => {
       
        console.log("err", err)});

        // this.setState({
        //           weatherModal: true,
        //         });
  };
  hideModalHandler = () => {
    this.setState({
      weatherModal: false,
    });
  };
  /*Pagination */
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });
  };
  /*End of pagination */

  render() {
    
    const { farmSites, subCategoriesList, contentOwners, consumerUsers } =
      this.state;
    /*Filter Functionality*/
    const farmSitesFilteredData = farmSites.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    return (
      <Container
        id="tendersContainer"
        className="container-fluid gbr-engage-container"
      >
        <SitwWeatherModal
          props={this.state.weatherData}
          Show={this.state.weatherModal}
          onclicked={this.hideModalHandler}
          forecast={this.state.forecastWeatherData}
        />

        <div className="mb-4">
          <div className="dropdown">
            <div className="dropdown homepage-nav-item">
              <div className="navbarRight">
                <button
                  className="btn btn-success homepageBtn"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.contentOwnerDropdownClick()}
                >
                  Club Owners
                </button>
              </div>


              <div className="navbarRight">
           
                <NavLink className="btn btn-success homepageBtn" to="/auth/3/signup">Add your Farm</NavLink> 
              </div>
              <div
                className="dropdown-menu"
                id="AssetDeveloperOwner"
                aria-labelledby="AssetDeveloperOwner"
              >
                {consumerUsers &&
                  consumerUsers.map((filter, i) => (
                    <li key={i}>
                      <a
                        className="dropdown-item"
                        role="button"
                        onClick={() =>
                          this.getContentOwnerContent(filter.username)
                        }
                      >
                        {filter.username}
                      </a>
                    </li>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="adminTendersDiv">
          {farmSitesFilteredData.length > 0 ? (
            <Row className="justify-content-center">
              {farmSitesFilteredData.map((site, index) => (
                <Col md={6} lg={4} sm={12} xs={12} className="mb-4" key={index}>
                  <div className="community-cards w-100">
                    <div className="bordered-div"></div>
                    <div className="">
                      <div className="scrollable-container scrollable-container-weather">
                        <div className="community-card-body">
                          {/* <h2 className="">{site.contentname}</h2> */}

                          <h2 className="mb-2 mt-2">
                            {site.sitename}
                            {site.timestamp}
                          </h2>
                          <p>
                            <b>Site Type: </b>
                            {site.sitetype}
                          </p>
                          <p>
                            <b>Latitude: </b>
                            {site.latitude}
                          </p>
                          <p>
                            <b>Longitude: </b>
                            {site.longitude}

                          </p>
                        </div>
                      </div>
                      <div className="community-button-container p-3 px-0 text-center ">
                        <button
                          onClick={() => this.weatherReportHandler(site,this.state.ApiUserName)}
                          // className={`Formbutton mx-auto d-block ${site.longitude}="null"? `} 

                          className={`Formbutton mx-auto d-block ${site.longitude === "null" ? "disabled bt-disabled" : ""}`} 
                          disabled={site.longitude === "null"}
                        
                          type="submit"
                        >
                          Check Weather
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              <div className="paginationComponent">
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </Row>
          ) : (
            <div className="card">
              <p className="card-body container-heading2">
                <b>Farms Not Found</b>
              </p>
            </div>
          )}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            $("#AssetDevelopers").hide();
            $("#AssetDeveloperOwner").hide();
          }}
        ><></></OutsideClickHandler>
        <br></br>
      </Container>
    );
  }
}

export default SiteWeatherManagement;
