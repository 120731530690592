import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import DigitalTrust from "../../../src/images/aboutImg/closeup-shot-beekeeper-holding-honeycombs-frame-with-many-bees-making-honey.jpg";
import Trading from "../../../src/images/aboutImg/care-services.jpg";
import Blockchain from "../../../src/images/aboutImg/business-success-concept-wooden-table-top-view-hands-protecting-wooden-figures-people (1).jpg";
import Farming from "../../../src/images/aboutImg/shot-beekeeper-beekeeping-suit-standing-near-row-beehives-his-apiary-copyspace.jpg";
import CareImg from "../../../src/images/aboutImg/full-length-view-beekeeper-working-landscapes-with-confident-expression-agrarian-natural-summer-honeycombs-concept.jpg";
import "react-awesome-slider/dist/styles.css";
import AboutBanner from "./AboutBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";

export default function AboutusComponent() {
  return (
    <>
      <HeaderComponent />
      <AboutBanner />
      <div className="about-main-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          // description="GBR create Social & Business Impact by transforming the Farmers into Agri-Entrepreneurs & Agri-Entrepreneurs into Agri-Business.
description="We exist to amplify farmers' yields by 50%, driving agricultural prosperity and food security."
          // description="GBR Farming generates social and business impact by empowering farmers to become Agri-entrepreneurs through transformational initiatives."

          title="Why We Exist"
          src={Farming}
        />

        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          // description="GBR introducing MONEY MAKING ASSETS for Agri-Entrepreneurs."
        description="We are a technology-enabled agri-healthcare company dedicated to revolutionizing agriculture through innovative solutions."
          title="
        Who We Are"
          src={DigitalTrust}
        />

        <AboutSubSection
          className="rowReverseAbout"
          description="We prioritize the well-being and success of farmers and agri-input retailers, ensuring their needs are at the heart of everything we do."
          // description="GBR is dedicated to supporting farmers who aspire to transition into agri-entrepreneurs."

          title=" Whom We Care About"
          src={CareImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description={[
            " We facilitate accessible care services for both plants and animals, simplifying agricultural management and enhancing productivity for farmers.",
          ]}
  
          title="What We Do"
          src={Trading}
        />
        <AboutSubSection
          className="rowReverseAbout"
          description="We own and manage Agri Retailer Network as part of  Community Based Network (CBN) in rural areas for the approved input retailers. Through this network we empower input retailers to serve the farmers."
          title="How We Do It"
          src={Blockchain}
        />

        {/* <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description16="Innovation and Value Creation"
          description1="We continuously work on VALUE CREATION for farmers
        through technology as an enabler. We encourage Innovation and Challenge the status quo."
          description17="Passion"
          description8="We are ambitious and believe in Teamwork
       to achieve more when we collaborate and all work together."
          description18="Courage and Trust"
          description12=" We are willing to take risks and
        Win-Win Partnerships With Our Business Partners. We view
        Farmers Service Providers and financial partners as trustworthy
        allies in serving farmers."
          description19="Equality"
          description20="We treat Farmers and Agri-Input Retailers with
        respect, fairness, transparency and integrity. We embrace Diversity of experience, Culture and Opinions and belive in sharing as learning for everyone."
          title="Our Culture & Values"
          src={Values}
        />
        <AboutSubSection
          className1="pb-0"
          className="rowReverseAbout "
          description="Our exceptional team, from dedicated employees to visionary leaders, fuels our success and propels us towards new heights. With open communication, trust, and collaboration at the core, we embrace innovation and achieve collective greatness. Together, we are shaping the future and proudly driving GBR Farming forward."
          title="
        Our Team"
          src={TeamImg}
        /> */}
      </div>

      <FooterComponent />
    </>
  );
}
