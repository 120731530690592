import React from "react";
import FarmerImg from "../../../images/gbrCBN/CBNImg3.webp";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import AdvisorImg from "../../../images/AnimalVet1.jpg";

import HomeSubSection from "../HomeSubSection/HomeSubSection";

import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";

import CBN from "../../../images/GBR Care (CBN Main Network) - GBR Care Main - 04-06-2024 N.gif";
import { Col, Container, Row } from "react-bootstrap";

// import FinancialServiceProviderImg from "../../../images/loan-business-finance-businessman-explain-business-report-from-data-analysis-bank-marketing.jpg";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import CBNMainServices from "./CBNMainServices";
import CBNNetworkBenefits from "./CBNNetworkBenefit";
export default function CBNNetwork() {
  return (
    <>
      <HeaderComponent />
      {/* <NetworkBanner
        bannerTitle='CBO & Agro Dealers can BUILD, OWN, MANAGE & OPERATE Community to help farmers with everything they need from seed to sale.'
        bodycopy='Local Trade | Quality Inputs | Insurance | Advisory | Capital'
        className='cbnbg'
      /> */}

      <UpdatesBanner
        className="cbnbg text-white"
        bannerTitle="Seed to Sale"
        bannerbodycopy1={[
         <span key="1">GBR Care can BUILD, MANAGE & OPERATE its own customised CBN network</span>,
          <br key="2" className="d-md-block d-none"></br>,
          <span key="3">to help farmers with everything they need from seed to sale</span>,
        ]}
        demo="true"
        bannerbodycopy="Local Trade | Quality Inputs | Insurance | Advisory | Capital"
      />

      <div className="subSectionWrapper">
        <h4 className="container-title text-center mx-md-4 ">
          Managed Community Based Network
        </h4>
        <Container>
          <Row className="justify-content-center">
            <Col md={6} sm={12}>
              <img
                className=" w-100 h-100 cursor-initial"
                src={CBN}
                alt="Infographics"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <CBNMainServices />
      <CBNNetworkBenefits />
      <div className=" subSectionWrapper">
        <h2 className="text-center container-title">CBN BENEFICIARIES</h2>

        {/* <h2 className="container-title text-center">Our Community</h2> */}

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image16"
          description="Collectives encompass a diverse range of entities such as agricultural cooperatives, farmer producer organizations (FPOs), agribusiness companies, and local farmer markets. These groups function as intermediaries, buying from and selling to farmers, providing crucial market access, collective purchasing power, and support services.
            "
          description1="FPCs | NGOs | SHG | CS"
          descriptionTitle="
          Collectives"
          src={AdvisorImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "Collective");
          }}
        />

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image18"
          description="Farmers are individuals or groups engaged in agricultural activities, including cultivating crops, raising livestock, or producing other agricultural commodities. They play a crucial role in food production, contributing to the supply of fruits, vegetables, grains, meats, and other essential products. Farmers often work on their own land or may lease or rent land for cultivation. Their activities involve planting, tending, and harvesting crops, as well as caring for animals and managing farm operations. 
            "
          descriptionTitle="
            Farmer"
          src={FarmerImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "Farmer");
          }}
        />

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image17"
          descriptionTitle="
            Traders"
          description="GBR is a technology company based in Bangalore that
          provides an alternate innovate options for agri-MSMEs
          Founders to maximize profit [reduce procurement cost &
          export] and raise easy investments through IAA.
          "
          src={TradersImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image19"
          descriptionTitle="
            Farm Machinery Providers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={MachineryImg}
          href2="auth/19/cbnsignup"
          buttoncont2="Register"
          href1="auth/19/cbnsignin"
          buttoncont1="Sign In"
          userType="Farmers-Machinery-Provider"
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image20"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale farmers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Nutrition Companies"
          src={NutritionCompaniesImg}
          href2="auth/20/cbnsignup"
          buttoncont2="Register"
          href1="auth/20/cbnsignin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image21"
          descriptionTitle="
            FPO,CS,NGO,SHG"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={NGOImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image23"
          descriptionTitle="
            Storage Providers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={StorageProviderImg}
          href2="auth/21/cbnsignup"
          buttoncont2="Register"
          href1="auth/21/cbnsignin"
          buttoncont1="Sign In"
          userType="Storage Providers"
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image24"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale farmers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Agri Input Companies"
          src={AgriInputImg}
          href2="auth/22/cbnsignup"
          buttoncont2="Register"
          href1="auth/22/cbnsignin"
          buttoncont1="Sign In"
          userType="Agri Input Companies"
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale farmers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Transporters"
          src={TransporterImg}
          href2="auth/23/cbnsignup"
          buttoncont2="Register"
          href1="auth/23/cbnsignin"
          buttoncont1="Sign In"
          userType="Transporters"
        /> */}
        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          description="NGOs are non-profit organizations focused on supporting agricultural development and rural communities. They offer services like education, training, and technical assistance to farmers, helping them adopt sustainable practices and access markets, ultimately promoting food security and rural development.


            "
          descriptionTitle="
            NGO"
          src={TransporterImg}
          href2="/auth/3/signup"
          buttoncont2="Register"
          href1="/auth/3/signin"
          buttoncont1="Sign In"
          userType="Transporters"
          className3="ngo-img"
        />
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="SHGs (Self-Help Groups) are community-based organizations formed by farmers to pool resources, share knowledge, and collectively engage in agricultural activities. These groups empower farmers, particularly women, by providing access to credit, training, and market linkages, thus enhancing their livelihoods and promoting socio-economic development in rural areas.


            "
          descriptionTitle="
            SHGs"
          src={TransporterImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          userType="Transporters"
          className3="shg-img"
        />

<HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          description="FPOs are collective groups formed by farmers to improve their bargaining power, access resources, and market their produce together. They empower small farmers by pooling resources, sharing knowledge, and facilitating access to credit and markets, thereby enhancing livelihoods and promoting sustainable agriculture in rural areas.


            "
          descriptionTitle="
            FPOs"
          src={TransporterImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          userType="Transporters"
          className3="fpo-img"
        />

<HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="CS are farmer-led organizations that enable collective action to address agricultural challenges and improve economic conditions. They help farmers pool resources, access markets, and obtain inputs at lower costs, while providing essential services like credit and technical assistance, fostering rural development.


            "
          descriptionTitle="
            CS"
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
          userType="Transporters"
          className3="cs-img"
        />
 */}

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="agri-retailer-role"
          description="Agri-retailers are businesses that specialize in selling agricultural inputs such as seeds, fertilizers, pesticides, and farm equipment to farmers. They often provide a wide range of products and services tailored to the needs of the agricultural community, including agronomic advice, soil testing, and crop protection solutions. Agri-retailers play a crucial role in the agricultural supply chain, facilitating the distribution of essential inputs to farmers and supporting their efforts to maximize yields and profitability.
              "
          descriptionTitle="
              Agri Retailer"
          src={ProductionManagerImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "Agri-Retailer");
          }}
          description1="Farm Machinery Retailers | Nutrition Retailers | Agri Input Retailers"
        />

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image18"
          description="GBR Care enables individual consultant to earn extra earnings anytime, from anywhere by just uploading farm and processing outlet details through the app. Once these details gets authenticated and approved by GBR Care representative, you will get your commission in your application digital wallet and you can transfer from your digital wallet to bank account.




            "
          descriptionTitle="
            Agriminer"
          src={FarmerImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "AgriMiner");
          }}
        />

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image25"
          descriptionTitle="
            Drivers"
          description="GBR is a technology company based in Bangalore that
    provides an alternate innovate options for agri-MSMEs
    Founders to maximize profit [reduce procurement cost &
    export] and raise easy investments through IAA.
    "
          src={DriverImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image26"
          descriptionTitle="
              Farmer Franchise"
          description="GBR is a technology company based in Bangalore that
      provides an alternate innovate options for agri-MSMEs
      Founders to maximize profit [reduce procurement cost &
      export] and raise easy investments through IAA.
      "
          src={FarmersFranchiseImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image27"
          descriptionTitle="
            Off-Takers"
          description="If you’re a modern retail company, hotel, or restaurant that wants to purchase fresh and processed food from trusted partner, we can give you what you’re looking for on best price"
          description1="Tracceable food | Disease Free | Certified"
          src={OffTakerImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}
      </div>

      {/* <div className="subSectionWrapper main-wrapper2">
        <CBNManager/>
        </div> */}

      {/* <CBNNetworkProducts/> */}
      <FooterComponent />
    </>
  );
}
