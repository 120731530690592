import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import CTA from "../CTA/CTA";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";

import CollectivesBenefits from "./CollectivesBenefits";
import CollectiveServices from "./CollectiveServices";
import CollectiveChallenges from "./CollectiveChallenges";
import CollectivesJourney from "./CollectivesJourney";
// import CollectiveContribution from "./CollectiveContribution";
import CollectivesPartnerServices from "./CollectivesPartnerServices";
export default function Collective() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="agriretailer-bg"
        bannerText="Collectives"  
      />
        <div className=" subSectionWrapper mb-0">
        <Container>
          <TitleDescription
            titleClass="text-start"
            classname2="text-start"
            classname="mb-0 mt-0 "
            title="Who are Collectives"
            // description="GBR Farming's agri-retailers play a pivotal role in the agricultural value chain by acting as intermediaries. These retailers specialize in selling essential agri-inputs to agri-entrepreneurs, leveraging their own selling capacity. GBR Farming's agri-retailers focus on maximizing their own distribution capabilities and market presence to effectively monetize the sale of agricultural inputs. This strategic approach enables them to enhance profitability and establish a robust position in the agri-business landscape, contributing to the overall success of GBR Farming in the agricultural market.
            // "
            description="Collectives in the agricultural sector encompass various organized groups, including NGOs (Non-Governmental Organizations), SHGs (Self-Help Groups), FPOs (Farmer Producer Organizations), and Cooperative Societies (CS). These groups aim to enhance the economic and social well-being of their members by pooling resources, sharing knowledge, and improving access to markets and services. NGOs often provide training and advocacy, SHGs support mutual savings and credit, FPOs work to increase production and marketing efficiency, and Cooperative Societies enable collective management of agricultural activities. Through collaboration, these collectives help farmers achieve greater productivity and profitability."
          />

          {/* <AgriBusinessCard /> */}
        </Container>
      </div>
      <CollectiveChallenges />
      <SolutionEcosystem
                title="Agribusiness Solution for Collectives"

        type="LTN"
        bodycopy="Ecosystem to do local trade & local distribution with farmer."
      />
    
      {/* <Container> */}
      <CollectiveServices />
      {/* </Container> */}

      <CollectivesPartnerServices/>
      <div className="main-wrapper2">
        <CollectivesBenefits/>
      </div>

      {/* <div className="subSectionWrapper">
       
          <GrowerBenefits />
   
      </div> */}
     
      
          <div className="subSectionWrapper">
          <Container>
          <CollectivesJourney/>
          </Container>
        </div>
        {/* <div className="main-wrapper2">
          <Container>
          <CollectiveContribution/>
          </Container>
        </div> */}
      {/* <Container className="subSectionWrapper pb-0">
          <h2 className="text-center title mb-4 mb-lg-5 container-title">
            Pricing
          </h2>
          <AgriRetailerPricing />
        </Container> */}
      <Container>
        <div className=" subSectionWrapper">
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "Collective");
            }}
            href="/auth/3/signup"
            heading="Elevate Your Agricultural Expertise Join Us as a Collectives!"
            btnText="Register Now"
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
