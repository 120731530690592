import React from "react";
import img1 from "../../images/optionalChoice.png";
import img3 from "../../images/job-offer.png";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AgriRetailerContribution() {
  const benefits = [
    {
      img: img1,
      title: "New Asset Class",
      bodycopy: "Can provide new Asset Class for investment that is tangible for investors.",
    },
    // {
    //   img: img2,
    //   title: "Improve Economy",
    //   bodycopy: "Investable trade in Agriculture bring a cascading increase in Agricultural production and  improves the GDP.",
    // },
    {
      img: img3,
      title: "Create Jobs",
      bodycopy:
        "Create a jobs to distribute agri-inputs to rural local area.",
    },
  ];
  return (
    <div className=" subSectionWrapper">
      <h2 className="text-center container-title">
        Agri-Retailer Contribution
      </h2>
      <Row className="justify-content-center">
        {benefits.map((e,i) => {
          return (
            <Col md={4} className="d-flex" key={i}>
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
