import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/collectives-challenges/collectives-challenges.png";

import EasyDistribution from "../../images/collectives-challenges/easy-distribution.svg";
import SalesMarket from "../../images/collectives-challenges/sales-market.svg";

const CollectiveChallenges = () => {
    const challanges =[{
        title:"Easy Distribution",
        description:"Easy distribution of products is a challenge for collectives due to the lack of a robust mechanism to ensure and verify that products have been distributed to farmers. This gap creates inefficiencies and hinders effective supply chain management.",
        image:EasyDistribution
      },
    
 
      {
        title:"Sales & Market Facilitation",
        description:"Sales and market facilitation pose significant challenges for collectives, as they often struggle to sell the produce they acquire from farmers. Without efficient sales channels and market access, collectives face difficulties in ensuring profitability and sustainability.",
        image:SalesMarket
      },
    //   {
    //     title:"Market Access",
    //     description:"Farmers face the challenge of limited market access, hindering their ability to reach a broader audience and maximize the potential for their agricultural products.",
    //     image:Ecosystem
    //   },
    
      // {
      //   title:"Brand ",
      //   description:"Branding empowers farmers to establish unique identities for their products, enabling differentiation and market recognition. ",
      //   image:Brand
      // }
    //  {
    //     title:"Easy Technology adoption ",
    //     description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by farmers.",
    //     image:Technology
    //   }
    ]
    return(
      <div className="main-wrapper2">
        <Container className="challenge-container subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">
          Collective challenges
        </h2>
  
        <Row className="align-items-center">
          <Col md={4}>
            <img src={challangeImg} alt="challange" className="mb-md-0 mb-4" />
          </Col>
  
          <Col md={8}>
           
  
            <Row className="challangesRow justify-content-between ">
              {challanges.map((e,i)=>{
                return <Col md={6} sm={6} xs={10} className="d-flex" key={i}>
                <div className="challangeCards  mb-md-4 ">
                  <img src={e.image} alt="Challange" className="mb-3" />
                  <h6 className="mb-2">{e.title}</h6>
                  <p className="mb-0">
                   {e.description}
                  </p>
                </div>
              </Col>
             
              })}          
            </Row>
     
          </Col>
        </Row>
      </Container>
      </div>
    )
};

export default CollectiveChallenges
